import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';
import { motion } from 'framer-motion';
import { useInViewEffect } from 'react-hook-inview';

import nbr_1 from '../images/nbr_01.svg';
import nbr_2 from '../images/nbr_02.svg';
import nbr_3 from '../images/nbr_03.svg';

import swissTranslate from '../images/SwissTranslate-Geneve.jpg';
import logo from '../images/logo.svg';

import shema_techs from '../images/shema_technologies.png';

const BlockUSP = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 0.5 });

	return (
		<>
			<motion.h2
				initial={{ y: -50, opacity: 0 }}
				animate={{ y: isVisible ? 0 : -50, opacity: isVisible ? 1 : 0 }}
			>
				Boostez votre communication digitale avec une landing page !
			</motion.h2>

			<div ref={ref} className="content-txt">
				<motion.div
					initial={{ x: 100, opacity: 0 }}
					animate={{ x: isVisible ? 0 : 100, opacity: isVisible ? 1 : 0 }}
				>
					<p style={{ fontSize: '1.14rem', lettersSpacing: '2px', wordSpacing: '0.2rem' }}>
						Une landing page (ou page d'atterissage) ne remplace pas votre site web actuel, elle
						vous offre un nouveau canal de communication rapide et performant au travers d'une page web
						et d'une campagne de publicité Google Ads pour mettre en avant un produit ou un service
						précis de votre entreprise, et ainsi générer des prospects de qualités et
						répondre le plus directement possible à l’intention de l’internaute.
					</p>
				</motion.div>
			</div>
		</>
	);
};

const BlockText1 = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 1 });

	return (
		<>
			<motion.h2
				initial={{ y: -50, opacity: 0 }}
				animate={{ y: isVisible ? 0 : -50, opacity: isVisible ? 1 : 0 }}
				style={{ textAlign: 'left' }}
			>
				Performance et rapidité
			</motion.h2>

			<div className="content-txt">
				<Row gutter={32} align="middle">
					<Col xs={24} lg={6} style={{ textAlign: 'center' }}>
						<svg
							ref={ref}
							className="img-icons"
							xmlns="http://www.w3.org/2000/svg"
							xmlnsXlink="http://www.w3.org/1999/xlink"
							viewBox="0 0 512 512"
							xmlSpace="preserve"
						>
							<motion.path
								className="img-icons-path"
								d="M423.5,198.2c48.9,48.8,78.7,113.4,84,182.3"
								initial={false}
								animate={{ pathLength: isVisible ? 1 : 0 }}
								transition={{ delay: 0.3, duration: 1 }}
							/>
							<motion.path
								className="img-icons-path"
								d="M5.2,196.9C110.8,91.3,278.7,81.3,396.1,173.7"
								initial={false}
								animate={{ pathLength: isVisible ? 1 : 0 }}
								transition={{ delay: 0.3, duration: 1 }}
							/>
							<motion.path
								className="img-icons-path"
								d="M380.6,250.8c32.8,36,53.4,81.4,59.1,129.7"
								initial={false}
								animate={{ pathLength: isVisible ? 1 : 0 }}
								transition={{ delay: 0.3, duration: 1 }}
							/>
							<motion.path
								className="img-icons-path"
								d="M52.9,244.6c77.8-77.7,200.8-85.3,289.2-27"
								initial={false}
								animate={{ pathLength: isVisible ? 1 : 0 }}
								transition={{ delay: 0.3, duration: 1 }}
							/>
							<motion.path
								className="img-icons-path"
								d="M289.1,353.4c30.7-30.7,89.5-98.9,135.1-155.4c37.2-46.4,65.7-84.8,62.2-88.3c-7.6-7.6-187.7,141.4-243.6,197.3s-64.7,78.6-48.5,94.9C210.4,418.2,233.2,409.1,289.1,353.4z"
								initial={false}
								animate={{ pathLength: isVisible ? 1 : 0 }}
								transition={{ delay: 0.3, duration: 1 }}
							/>
						</svg>
					</Col>
					<Col xs={24} lg={18}>
						<motion.div
							initial={{ x: 100, opacity: 0 }}
							animate={{ x: isVisible ? 0 : 100, opacity: isVisible ? 1 : 0 }}
						>
							<p>
								Nous faisons du référencement depuis son apparition. Grâce à notre expérience nous avons
								développé une solution spécialisée et extrêmement performante permettant un placement
								optimal dans les résultats. Cette solution est régulièrement mise à jour pour répondre
								aux critères imposés par les algorithmes de recherche.
							</p>
						</motion.div>
					</Col>
				</Row>
			</div>
		</>
	);
};

const BlockText2 = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 1 });

	return (
		<>
			<motion.h2
				initial={{ y: -50, opacity: 0 }}
				animate={{ y: isVisible ? 0 : -50, opacity: isVisible ? 1 : 0 }}
				style={{ textAlign: 'left' }}
			>
				Une expertise à tous les niveaux
			</motion.h2>

			<div className="content-txt">
				<Row gutter={32} align="middle">
					<Col xs={24} lg={{ offset: 6, span: 18 }} style={{ textAlign: 'center' }}>
						<a href="https://www.swisstranslate.ch/" target="_blank" rel="noopener">
							<img src={swissTranslate} className="partners-logo" />
						</a>
					</Col>
				</Row>
				<Row gutter={32} align="middle">
					<Col xs={24} lg={6} style={{ textAlign: 'center' }}>
						<svg
							ref={ref}
							className="img-icons"
							xmlns="http://www.w3.org/2000/svg"
							xmlnsXlink="http://www.w3.org/1999/xlink"
							viewBox="0 0 512 512"
							xmlSpace="preserve"
						>
							<motion.path
								className="img-icons-path"
								d="M421.7,42.6C363,13,296.8,31.4,256,83c-41-51.5-107.3-70-165.8-40.4C16.5,79.8-17.1,178.8,15.2,263.8c31.4,82.9,201.9,224,240.6,224c39.2,0,209.2-141,240.6-223.9C528.7,178.8,495.1,79.8,421.7,42.6z"
								initial={false}
								animate={{ pathLength: isVisible ? 1 : 0 }}
								transition={{ delay: 0.3, duration: 1 }}
							/>
						</svg>
					</Col>
					<Col xs={24} lg={18}>
						<motion.div
							initial={{ x: 100, opacity: 0 }}
							animate={{ x: isVisible ? 0 : 100, opacity: isVisible ? 1 : 0 }}
						>
							{/*<p>
								Grâce aux différents abonnements disponibles, une campagne publicitaire Google Ads
								est directement créée pour mettre en avant votre landing page.
								Nous veillons également à la visibilité de votre page via un suivi du référencement
								(SEO) et suivi des campagnes publicitaire (SEA) par notre équipe d’experts.
							</p>*/}
							<p>
								Le contenu est également un point primordial au bon référencement naturel (SEO) de
								votre landing page. Nous avons donc établi un partenariat avec SwissTranslate afin de
								vous offrir une rédaction de textes professionnels optimisés pour les moteurs de
								recherches et pour séduire votre future clientèle.
							</p>
						</motion.div>
					</Col>
				</Row>
			</div>
		</>
	);
};

const BlockText3 = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 1 });

	return (
		<>
			<motion.h2
				initial={{ y: -50, opacity: 0 }}
				animate={{ y: isVisible ? 0 : -50, opacity: isVisible ? 1 : 0 }}
				style={{ textAlign: 'left' }}
			>
				Pour une économie locale
			</motion.h2>

			<div className="content-txt">
				<Row gutter={32} align="middle">
					<Col xs={24} lg={6} style={{ textAlign: 'center' }}>
						<svg
							ref={ref}
							className="img-icons"
							xmlns="http://www.w3.org/2000/svg"
							xmlnsXlink="http://www.w3.org/1999/xlink"
							viewBox="0 0 512 512"
							xmlSpace="preserve"
						>
							<motion.path
								className="img-icons-path"
								d="M153.1,354L60.9 507 452.3 507 419.3 354z"
								initial={false}
								animate={{ pathLength: isVisible ? 1 : 0 }}
								transition={{ delay: 0.3, duration: 1 }}
							/>
							<motion.path
								className="img-icons-path"
								d="M253.4,371.5L245.3 405.1 166.6 405.1 154.3 431 237.5 431 225.3 477.9 295.7 477.9 299.2 433.4 383.2 433.4 381 404.7 301.6 404.5 303.5 371.5z"
								initial={false}
								animate={{ pathLength: isVisible ? 1 : 0 }}
								transition={{ delay: 0.3, duration: 1 }}
							/>
							<motion.path
								className="img-icons-path"
								d="M272.4,4.4c-81.1,0.2-146.8,66.1-146.7,147.2c0.2,81.2,109,277.1,147.5,277.1c38.5-0.1,146.6-196.5,146.4-277.6C419.4,69.9,353.5,4.3,272.4,4.4z M272.8,228.8c-44.9,0.1-81.4-36.3-81.5-81.2s36.3-81.4,81.2-81.5s81.4,36.3,81.5,81.2c0,0,0,0,0,0C354.1,192.2,317.7,228.7,272.8,228.8C272.8,228.8,272.8,228.8,272.8,228.8z"
								initial={false}
								animate={{ pathLength: isVisible ? 1 : 0 }}
								transition={{ delay: 0.3, duration: 1 }}
							/>
						</svg>
					</Col>
					<Col xs={24} lg={18}>
						<motion.div
							initial={{ x: 100, opacity: 0 }}
							animate={{ x: isVisible ? 0 : 100, opacity: isVisible ? 1 : 0 }}
						>
							<p>
								Nous sommes basés à Genève et nous offrons aux entrepreneurs et entreprises genevoises
								la possibilité de se mettre en avant dans les résultats des moteurs de recherches pour
								leur clientèle locale avec une campagne de référencement payante (SEA) ciblée et suivie.
							</p>
						</motion.div>
					</Col>
				</Row>
			</div>
		</>
	);
};

const StepText1 = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 1 });

	return (
		<Col ref={ref} xs={24} md={24} lg={8}>
			<motion.div
				initial={{ y: -100, opacity: 0 }}
				animate={{ y: isVisible ? 0 : -100, opacity: isVisible ? 1 : 0 }}
				transition={{ type: 'spring' }}
				style={{ display: 'flex', flexDirection: 'column', padding: '24px' }}
			>
				<img src={nbr_1} style={{ height: 48, marginBottom: '3vw', marginTop: '3vw' }} />

				<div className="separator-green" />

				<p>
					Au travers d’un audit gratuit, nous analysons le potentiel de votre offre à travers
					plusieurs critères, dont le volume de recherche sur les moteurs de recherche.
				</p>
			</motion.div>
		</Col>
	);
};

const StepText2 = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 1 });

	return (
		<Col ref={ref} xs={24} md={24} lg={8}>
			<motion.div
				initial={{ y: -100, opacity: 0 }}
				animate={{ y: isVisible ? 0 : -100, opacity: isVisible ? 1 : 0 }}
				transition={{ delay: 0.1, type: 'spring' }}
				style={{ display: 'flex', flexDirection: 'column', padding: '24px' }}
			>
				<img src={nbr_2} style={{ height: 48, marginBottom: '3vw', marginTop: '3vw' }} />

				<div className="separator-green" />

				<p>
					Nous nous occupons de tout pour la création de votre landing page ! La page est créée
					par nos soins et le contenu rédigés par des professionnels de la rédaction.
				</p>
			</motion.div>
		</Col>
	);
};

const StepText3 = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 1 });

	return (
		<Col ref={ref} xs={24} md={24} lg={8}>
			<motion.div
				initial={{ y: -100, opacity: 0 }}
				animate={{ y: isVisible ? 0 : -100, opacity: isVisible ? 1 : 0 }}
				transition={{ delay: 0.2, type: 'spring' }}
				style={{ display: 'flex', flexDirection: 'column', padding: '24px' }}
			>
				<img src={nbr_3} style={{ height: 48, marginBottom: '3vw', marginTop: '3vw' }} />

				<div className="separator-green" />

				<p>
					Vous aurez également le choix de donner, périodiquement, des coups de boosts à
					votre landing page, afin d'augmenter votre portée en proposant également d’autres
					canaux de publicités.
				</p>
			</motion.div>
		</Col>
	);
};

const Intro = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 1 });

	return (
		<div id="intro">
			<div style={{ position: 'relative', marginBottom: 60, textAlign: 'center' }}>
				<motion.img
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					src={logo}
					style={{ width: 128, marginBottom: 40 }}
				/>

				<BlockUSP />

				<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.6 }}
				            style={{ textAlign: 'center' }}>
					<Button type="primary" size="large" style={{ marginTop: 40 }} href="#contact">
						Contactez-nous !
					</Button>
				</motion.div>


				<img src={shema_techs} alt="Technology Web" style={{ marginTop: 40, maxWidth: '100%' }} />

			</div>

			{/*<h2 className="h2-geneve-title">geneve.site</h2>
		<h4 className={'h4-usp-title'}>La nouvelle plateforme qui donne des vitamines à votre entreprise.</h4>

		<div className="video">
			<iframe
				src="https://www.youtube-nocookie.com/embed/NpEaa2P7qZI"
				frameBorder="0"
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</div>*/}

			<section id="about">
				<BlockText3 />
				<div className="separator" />
				<BlockText1 />
				<div className="separator" />
				<BlockText2 />

				{/*<div className="separator" style={{ marginTop: 40 }} />

			<div className="box-content" style={{ marginTop: '10vh' }}>
				<h4 style={{ marginTop: '2vw' }}>Notre démarche</h4>
				<Row gutter={32} justify="center">
					<StepText1 />
					<StepText2 />
					<StepText3 />
				</Row>
			</div>*/}


				{/*}
    <div className="columns">
      <div className="column">
        <h2>Vous êtes intéressé ?</h2>
        <div className="content-txt">
          <p>Nos landing pages seront bientôt disponibles pour les indépendants et entrepreneurs genevois, n’attendez
            plus et enregistrez votre email grâce au formulaire ci-dessous pour être tenu au courant du lancement de
            geneve.site !</p>
        </div>
      </div>
    </div>
    */}
			</section>

		</div>
	);
};

export default Intro;
