import React, { useState } from 'react';
import { Card, Col, Button, Modal, Row } from 'antd';
import { motion } from 'framer-motion';
import { useInViewEffect } from 'react-hook-inview';

import PackForm from './PackForm';
import logoVitamines from '../images/logo-vitamines.png';
import logoCafeine from '../images/logo-cafeine.png';

import logoEnergy from '../images/logo-energy.png';

import sectionDemo from '../images/sections.png';
import dot from '../images/dot.svg';

const LPContent1 = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 1 });

	return (
		<div ref={ref}>
			<motion.div
				initial={{ x: 100, opacity: 0 }}
				animate={{ x: isVisible ? 0 : 100, opacity: isVisible ? 1 : 0 }}
				transition={{ type: 'spring' }}
			>
				<Row gutter={32} align="middle" style={{ marginBottom: 24 }}>
					<Col xs={3} style={{ textAlign: 'center' }}><img src={dot} style={{ width: 24, height: 24 }} /></Col>
					<Col xs={21}>
						<p style={{ textAlign: 'left', marginBottom: 0 }}>
							La première section met l’offre en avant pour accrocher les visiteurs.
						</p>
					</Col>
				</Row>
			</motion.div>
		</div>
	)
};

const LPContent2 = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 1 });

	return (
		<div ref={ref}>
			<motion.div
				initial={{ x: 100, opacity: 0 }}
				animate={{ x: isVisible ? 0 : 100, opacity: isVisible ? 1 : 0 }}
				transition={{ delay: 0.1, type: 'spring' }}
			>
				<Row gutter={32} align="middle" style={{ marginBottom: 24 }}>
					<Col xs={3} style={{ textAlign: 'center' }}><img src={dot} style={{ width: 24, height: 24 }} /></Col>
					<Col xs={21}>
						<p style={{ textAlign: 'left', marginBottom: 0 }}>
							La deuxième section présente l’offre de manière détaillée.
						</p>
					</Col>
				</Row>
			</motion.div>
		</div>
	)
};

const LPContent3 = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 1 });

	return (
		<div ref={ref}>
			<motion.div
				initial={{ x: 100, opacity: 0 }}
				animate={{ x: isVisible ? 0 : 100, opacity: isVisible ? 1 : 0 }}
				transition={{ delay: 0.2, type: 'spring' }}
			>
				<Row gutter={32} align="middle" style={{ marginBottom: 24 }}>
					<Col xs={3} style={{ textAlign: 'center' }}><img src={dot} style={{ width: 24, height: 24 }} /></Col>
					<Col xs={21}>
						<p style={{ textAlign: 'left', marginBottom: 0 }}>
							La troisième section de la landing page donne aux visiteurs des moyens de prendre contact directement avec l’entreprise qui propose l’offre.
						</p>
					</Col>
				</Row>
			</motion.div>
		</div>
	)
};

const PriceVitamin = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 0.8 });

	return (
		<Col ref={ref} xs={24} md={24} lg={8}>
			<motion.div
				initial={{ scale: 0, opacity: 0 }}
				animate={{ scale: isVisible ? 1 : 0, opacity: isVisible ? 1 : 0 }}
				transition={{ type: 'spring' }}
			>
				<Card>
					<img src={logoVitamines} className="center" style={{ width: 92, paddingBottom: 20 }} alt="Logo pack vitamines pour PME"/>

					<h3>Vitamines</h3>

					<div className="price-infos">
						<span style={{ color: '#1a1a1a' }}>Prix :</span>
						<span className="price">350.-</span>
						<span style={{ color: '#1a1a1a', fontSize: '0.8em' }}>CHF / mois</span>
					</div>
					<div style={{ color: '#5a5a5a', letterSpacing: '1px', textAlign: 'center', marginTop: 32 }}>
						Inclus l'hébergement de la landing page ainsi que <strong>200.- CHF de crédits Google Ads</strong> par mois et le suivi SEO/SEA.
					</div>
				</Card>
			</motion.div>
		</Col>
	);
};

const PriceCafeine = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 0.8 });

	return (
		<Col ref={ref} xs={24} md={24} lg={8}>
			<motion.div
				initial={{ scale: 0, opacity: 0 }}
				animate={{ scale: isVisible ? 1 : 0, opacity: isVisible ? 1 : 0 }}
				transition={{ delay: 0.1, type: 'spring' }}
			>
				<Card>
					<img src={logoCafeine} className="center" style={{ width: 92, paddingBottom: 20 }}
					     alt="Logo pack vitamines pour PME"/>
					<h3>Caféine</h3>

					<div className="price-infos">
						<span style={{ color: '#1a1a1a' }}>Prix :</span>
						<span className="price">900.-</span>
						<span style={{ fontSize: '0.8em' }}>CHF / mois</span>
					</div>
					<div style={{ color: '#5a5a5a', letterSpacing: '1px', textAlign: 'center', marginTop: 32 }}>
						Inclus l'hébergement de la landing page ainsi que <strong>600.- CHF de crédits Google Ads</strong> par mois et le suivi SEO/SEA.
					</div>
				</Card>
			</motion.div>
		</Col>
	);
};

const PriceEnergy = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 0.8 });

	return (
		<Col ref={ref} xs={24} md={24} lg={8}>
			<motion.div
				initial={{ scale: 0, opacity: 0 }}
				animate={{ scale: isVisible ? 1 : 0, opacity: isVisible ? 1 : 0 }}
				transition={{ delay: 0.2, type: 'spring' }}
			>
				<Card>
					<img src={logoEnergy} className="center" style={{ width: 92, paddingBottom: 20 }}
					     alt="Logo pack vitamines pour PME"/>
					<h3>Énergie</h3>

					<div style={{ color: '#5a5a5a', letterSpacing: '1px', textAlign: 'center', marginTop: 32 }}>
						<p style={{ textAlign: 'center', fontSize: '1.2em' }}>Un pack sur-mesure adapté à vos besoins.</p>
						<a href="#contact">Contactez-nous pour en savoir plus.</a>
					</div>
				</Card>
			</motion.div>
		</Col>
	);
};

const BlockBoost = () => {
	const [isVisible, setIsVisible] = useState(false);

	const ref = useInViewEffect(([entry], observer) => {
		if (entry.isIntersecting)
			observer.unobserve(entry.target);

		setIsVisible(entry.isIntersecting);
	}, { threshold: 1 });

	return (
		<>
			<motion.h4
				initial={{ y: -50, opacity: 0 }}
				animate={{ y: isVisible ? 0 : -50, opacity: isVisible ? 1 : 0 }}
			>
				Boosts supplémentaires
			</motion.h4>

			<div style={{ textAlign: 'center' }}>
				<svg
					ref={ref}
					className="img-icons"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					viewBox="0 0 512 512"
					xmlSpace="preserve"
					style={{ marginBottom: 32 }}
				>
					<motion.path
						className="img-icons-path"
					    d="M13.9,231.2c0,97.5,353,97.5,353,0s-79-176.5-176.5-176.5S13.9,133.7,13.9,231.2z"
						initial={false}
						animate={{ pathLength: isVisible ? 1 : 0 }}
						transition={{ delay: 0.3, duration: 1 }}
					/>
					<motion.path
						className="img-icons-path"
					    d="M112.1,297.5c35.9,34.1-10.3,78.6-10.3,93.5c0,48.9,39.7,88.6,88.6,88.6S279,439.9,279,391c0-15.2-34.3-36.4-8.4-93.9"
						initial={false}
						animate={{ pathLength: isVisible ? 1 : 0 }}
						transition={{ delay: 0.3, duration: 1 }}
					/>
					<motion.path
						className="img-icons-path"
					    d="M409.3,145.4H377v-29.7h-31.3V84.4H377V54.7h32.3v29.7h31.3v31.3h-31.3V145.4z"
						initial={false}
						animate={{ pathLength: isVisible ? 1 : 0 }}
						transition={{ delay: 0.3, duration: 1 }}
					/>
					<motion.path
						className="img-icons-path"
					    d="M498.7,24.6v120.8H458V55.3h-20.7V24.6H498.7z"
						initial={false}
						animate={{ pathLength: isVisible ? 1 : 0 }}
						transition={{ delay: 0.3, duration: 1 }}
					/>
					<motion.path
						className="img-icons-path"
				        d="M36.900000000000006,214a55,55 0 1,0 110,0a55,55 0 1,0 -110,0"
						initial={false}
						animate={{ pathLength: isVisible ? 1 : 0 }}
						transition={{ delay: 0.3, duration: 1 }}
					/>
					<motion.path
						className="img-icons-path"
						d="M175.6,122.1a37.1,37.1 0 1,0 74.2,0a37.1,37.1 0 1,0 -74.2,0"
						initial={false}
						animate={{ pathLength: isVisible ? 1 : 0 }}
						transition={{ delay: 0.3, duration: 1 }}
					/>
					<motion.path
						className="img-icons-path"
						d="M229.20000000000002,216.5a42.9,42.9 0 1,0 85.8,0a42.9,42.9 0 1,0 -85.8,0"
						initial={false}
						animate={{ pathLength: isVisible ? 1 : 0 }}
						transition={{ delay: 0.3, duration: 1 }}
					/>
					<motion.path
						className="img-icons-path"
					    d="M218.1,458.7c27.2-10,46.7-36.1,46.7-66.8"
						initial={false}
						animate={{ pathLength: isVisible ? 1 : 0 }}
						transition={{ delay: 0.3, duration: 1 }}
					/>
				</svg>
			</div>
			<motion.div
				initial={{ x: 100, opacity: 0 }}
				animate={{ x: isVisible ? 0 : 100, opacity: isVisible ? 1 : 0 }}
			>
				<p>
					À des moments opportuns, il est possible de booster votre landing page en publiant sur différents
					canaux publicitaires payants des publicités supplémentaires. Notre équipe se fera un plaisir de
					vous guider dans ces démarches.
				</p>
			</motion.div>
		</>
	);
};

const Prices = () => (
		<div id="prices">
			<h2>Tarifs</h2>
			<div className="box-content" style={{ marginBottom: '2vw' }}>
				<h4 style={{ marginTop: '2vw' }}>Création de la landing page</h4>

				<div className="price-infos">
					<span style={{ color: '#9c9c9c' }}>À partir de</span>
					<span className="price" style={{ textDecoration: 'line-through', marginRight: 0, opacity: 0.5 }}>1'800.-</span>
					<span className="price">1'440.-</span>
					<span style={{ fontSize: '0.8em' }}>CHF</span>
				</div>

				<p style={{ marginTop: 32, opacity: 0.6 }}>
					<small>Profitez de l'offre de lancement à <strong style={{ color: '#f69d2d' }}>-20%</strong> pour la création de la landing page jusqu'au 12 décembre 2020.</small>
				</p>

				<Row gutter={32} align="middle" style={{ marginTop: 48 }}>
					<Col xs={24} lg={8}>
						<div style={{ textAlign: 'center' }}>
							<img src={sectionDemo} style={{ width: '40vw', maxWidth: '100%' }} />
						</div>
					</Col>
					<Col xs={24} lg={16}>
						<p style={{ marginBottom: 32 }}>La landing page classique est constituée de 3 sections de base.</p>
						<LPContent1 />
						<LPContent2 />
						<LPContent3 />
					</Col>
				</Row>

				<div className="separator-green"/>

				<h4 style={{ marginTop: '2vw' }}>Sections supplémentaires</h4>

				<div className="price-infos">
					<span style={{ color: '#9c9c9c' }}>À partir de</span>
					<span className="price">260.-</span>
					<span style={{ fontSize: '0.8em' }}>CHF / section</span>
				</div>

				<p style={{ marginTop: 32 }}>
					Il est possible d’ajouter des sections supplémentaire, pour présenter au mieux votre
					offre, comme par exemple une section “<strong>galerie photos</strong>” ou une section “<strong>caractéristiques
					techniques</strong>” du produit.
				</p>

				{/*<div className="separator-green"/>

				<h4 style={{ marginTop: '2vw' }}>Abonnement</h4>

				<div style={{
					margin: '20px 0',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<span className="price">200.-</span>&nbsp;&nbsp;<span style={{ fontSize: '0.8em' }}>CHF / mois</span>
				</div>

				<p style={{ marginBottom: 0 }}>
					Cet abonnement comprend l'hébergement (Suisse) sécurisé de la landing page et un budget de 75 CHF par mois sur Google Ads &trade; afin de mettre en avant votre landing page et d'optimiser les mots-clés de recherche, pour que les packs de boosts soient plus efficace une fois mis en place.
				</p>

				<div className="separator-green" />

				<div style={{
					marginTop: 20,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<span style={{ color: '#9c9c9c' }}>Total à partir de :</span>
					&nbsp;&nbsp;
					<span className="price">3'600.-</span>
					&nbsp;&nbsp;
					<span style={{ fontSize: '0.8em' }}>CHF / année</span>
				</div>*/}

				<div className="separator-green" />

				<h4>Abonnements</h4>

				<Row gutter={32} justify="center">
					<PriceVitamin />
					<PriceCafeine />
					<PriceEnergy />
				</Row>

				<div className="separator-green" />

				<BlockBoost />
			</div>


			{/*<h2>Préserver votre avantage</h2>

      <div className="box-content" style={{marginTop: "2vw" }}>
        <h4>Conserver votre landing page active.</h4>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
          <tbody>
          <tr>
            <td style={{ color: "#9c9c9c" }}>Prix :</td>
            <td><span className="price">130.-</span> <span style={{ fontSize: "0.8em" }}>CHF par mois</span></td>
          </tr>
          </tbody>
        </div>
        <div className="separator-green" />
        <p>
          Les landing page de geneve.site sont céer pour être performante sur une courte durée de temps pour  répondre à une demande de visibilité pour une offre, un service, ou un événement.
        </p>
        <p>
          Il est néanmoins possible de garder sa landing page active une fois cette période terminée. L'avantage est que vous conserver le nom de la landing page, la landing page continuer a convertir des visiteurs en clients et la notoriété de la page continuer à évoluer. Il est ainsi possible de doper la landing page et vos ventes grâce à un pack vitamines, caféine ou énergie a n’importe quel moment.
        </p>
      </div>

      <div className="box-content" style={{marginTop: "2vw" }}>
        <h4>Conserver votre mot clé sur la durée.</h4>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
          <table>
            <tbody>
            <tr>
              <td style={{ color: "#9c9c9c" }}>Prix :</td>
              <td><span className="price">30.-</span> <span style={{ fontSize: "0.8em" }}>CHF par mois</span></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="separator-green" />
        <div style={{padding: '32px'}}><h4><span style={{color: '#666'}}><strong>ma-landing-page.</strong></span><i>geneve.site</i></h4></div>
        <p>
          Un mot clé chez geneve.site est unique, il gagne en notoriété et donc en valeur avec le temps. Nous vous donnons la possibilité de rester propriétaire d’un mot clé pour éviter qu’il soit redistribué à la concurrence.
        </p>
      </div>

      <Modal
        visible={pack !== null}
        onCancel={() => setPack(null)}
        footer={null}
      >
        <PackForm pack={pack}/>
      </Modal>*/}
		</div>
);

export default Prices
