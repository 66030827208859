import React, { useState } from 'react';
import { Button, Checkbox, Col, Form, Icon, Input, Modal, Result, Row } from 'antd';
import { motion } from 'framer-motion';
import swissmade from '../images/swiss-made.png';
import logo from '../images/logo.svg';

const Contact = () => {
	const [form] = Form.useForm();
	const [ state, setState ] = useState('waiting');
	const [ gdpr, setGdpr ] = useState(false);

	const handleSubmit = async values => {
		setState('loading');

		const formData = new FormData();
		Object.keys(values).forEach(key => {
			formData.append(key, values[key]);
		});

		try {
			const req = await fetch('/api/contact', {
				method: 'POST',
				headers: { 'Accept': 'application/json' },
				body: formData
			});
			const res = await req.json();

			if (res.success) {
				setState('success');
				form.resetFields();
			}
			else {
				setState('error');

				setTimeout(() => {
					setState('waiting');
				}, 5000);
			}
		}
		catch(err) {
			setState('error');

			setTimeout(() => {
				setState('waiting');
			}, 5000);
		}
	};

	return (
		<>
			<h2 id="contact">Contact</h2>

			<section className="box-content" style={{ marginBottom: 40 }}>
				<Row gutter={32} align="middle">
					<Col xs={24} lg={12}>
						<div>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<img src={logo} style={{ width: 92 }} />
							</div>
							<h4 style={{ margin: '20px 0' }}>geneve.site</h4>
							<div>
								<p style={{ textAlign: 'center' }}>Route du Mandement, 15</p>
								<p style={{ textAlign: 'center' }}>1217 Meyrin, Genève</p>
								<p style={{ textAlign: 'center' }}><a
									href="mailto:contact@geneve.site">contact@geneve.site</a></p>
								<p style={{ textAlign: 'center', marginBottom: 0 }}><a href="tel:+41 78 496 66 06">+41
									44 597 24 82</a>
								</p>
							</div>
						</div>
						{/*<h4 style={{ margin: '20px 0 50px 0' }}>Prise de rendez-vous</h4>
						<div className="meetings-iframe-container" data-src="https://meetings.hubspot.com/info7676?embed=true" />*/}
					</Col>
					<Col xs={24} lg={12} style={{ position: 'relative' }}>
						<motion.div
							initial={{
								opacity: 0,
								height: 0
							}}
							animate={{
								opacity: state === 'success' ? 1 : 0,
								height: state === 'success' ? 'auto' : 0
							}}
							style={{ overflow: 'hidden' }}
						>
							<Result
								status="success"
								title="Envoi réussi"
								subTitle="Merci d'avoir pris contact avec nous, nous vous contacterons dans les plus brefs délais."
							/>,
						</motion.div>
						<motion.div
							initial={{
								opacity: 0,
								height: 0
							}}
							animate={{
								opacity: state === 'error' ? 1 : 0,
								height: state === 'error' ? 'auto' : 0
							}}
							style={{ overflow: 'hidden' }}
						>
							<Result
								status="error"
								title="Échec de l'envoi"
								subTitle="Une erreur est survenue lors de l'envoi de l'email. Merci de bien vouloir réessayer."
							/>,
						</motion.div>
						<motion.div
							intial={{
								opacity: 1,
								height: 'auto'
							}}
							animate={{
								opacity: state === 'waiting' ? 1 : 0,
								height: state === 'waiting' ? 'auto' : 0
							}}
							style={{ overflow: 'hidden' }}
						>
							<Form
								form={form}
								name="Contact depuis geneve.site"
								className="contact-form"
								layout="vertical"
								onFinish={handleSubmit}
								initialValues={{
									subject: 'Contact depuis geneve.site',
									firstname: '',
									lastname: '',
									email: '',
									phone: '',
									message: '',
									newsletter: false
								}}
							>
								<Form.Item label="Subject" name="subject" style={{ display: 'none' }}>
									<Input name="subject" placeholder="Subject" />
								</Form.Item>
								<Row gutter={32}>
									<Col xs={24} md={12}>
										<Form.Item label="Prénom" name="firstname"
										           rules={[{ required: true, message: 'Entrez votre prénom' }]}>
											<Input name="firstname" placeholder="Prénom" disabled={state === 'loading'} />
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item label="Nom" name="lastname"
										           rules={[{ required: true, message: 'Entrez votre nom' }]}>
											<Input name="lastname" placeholder="Nom" disabled={state === 'loading'} />
										</Form.Item>
									</Col>
								</Row>
								<Form.Item label="Email" name="email"
								           rules={[{ required: true, message: 'Entrez votre email' }]}>
									<Input name="email" placeholder="Email" disabled={state === 'loading'} />
								</Form.Item>
								<Form.Item label="Téléphone" name="phone">
									<Input name="phone" placeholder="Téléphone" disabled={state === 'loading'} />
								</Form.Item>
								<Form.Item label="Message" name="message">
									<Input.TextArea name="message" autoSize={{ minRows: 2, maxRows: 6 }}
									                placeholder="Message" disabled={state === 'loading'} />
								</Form.Item>
								<Form.Item name="newsletter" valuePropName="checked">
									<Checkbox name="newsletter" disabled={state === 'loading'}>J'accepte de recevoir des informations périodiques
										concernant
										geneve.site</Checkbox>
								</Form.Item>
								<Form.Item style={{ marginBottom: 0 }}>
									<Button type="primary" size="large" block={true} loading={state === 'loading'} htmlType="submit">Entrer en
										contact</Button>
								</Form.Item>
							</Form>
						</motion.div>
					</Col>
				</Row>

				<div className="separator-green" />

				<footer className="footer">
					<Row gutter={16} justify="space-between" align="middle">
						<Col xs={24} md={8}>
							&copy; {new Date().getFullYear()} <a href="https://www.nammu.ch" target="_blank"
							                                     rel="noopener">nammu.ch</a>
						</Col>
						<Col xs={24} md={8}>
						<span onClick={() => setGdpr(true)} style={{ fontSize: '0.8em', cursor: 'pointer' }}>
							Politique de confidentialité
						</span>
						</Col>
						<Col xs={24} md={8}>
							<img src={swissmade} /> Swiss made
						</Col>
					</Row>
				</footer>

				<Modal
					style={{ minWidth: '70vw' }}
					visible={gdpr}
					onCancel={() => setGdpr(false)}
					footer={null}
					centered={true}
				>
					<h3>Politique de confidentialité</h3>

					<span style={{
						fontSize: '1.3em',
						fontWeight: 'bold',
						color: '#3e8987',
						display: 'block'
					}}>INTRODUCTION</span>
					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Ce site Internet est géré par nammu.ch SNC
						dont
						le siège est en Suisse, Route du Mandement, 15, 1217 Meyrin à Genève. (ci-après : nammu).</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Nous nous engageons à traiter de manière
						responsable vos données personnelles. Nous considérons par conséquent qu'il va de soi de
						respecter
						les exigences légales de la loi fédérale suisse sur la protection des données (LPD), de
						l'ordonnance
						relative à la loi fédérale sur la protection des données (OLPD), de la loi sur les
						télécommunications (LTC) et des autres dispositions de la législation suisse sur la protection
						des
						données. Nous respecterons en outre les dispositions du nouveau règlement européen sur la
						protection
						des données (RGPD) dans la mesure où celui-ci nous serait applicable.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Nous souhaitons ci-après vous informer de
						la
						manière dont nous traitons vos données personnelles.</p>

					<span style={{
						fontSize: '1.3em',
						fontWeight: 'bold',
						color: '#3e8987',
						display: 'block',
						marginTop: '30px',
						marginBottom: '14px'
					}}>ÉTENDUE ET OBJET DE LA RÉCOLTE, DU TRAITEMENT ET DE L'UTILISATION DES DONNÉES PERSONNELLES</span>
					<span>VISITE SUR NOTRE SITE INTERNET</span>
					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Pour chaque visite de nos sites Internet,
						nos
						serveurs enregistrent provisoirement chaque accès dans un fichier de protocole.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>La récolte et le traitement de ces données
						se
						font en général à titre anonyme sans référence personnelle aux fins de permettre l'utilisation
						du
						site Internet (mise en place de la liaison), de garantir durablement la sécurité et la stabilité
						du
						système et d'optimiser l'offre Internet, ainsi qu'à des fins statistiques. Les informations
						susmentionnées ne sont pas reliées à des données personnelles ni enregistrées avec
						celles-ci.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Ce n'est qu'en cas d'attaque à
						l'infrastructure
						du réseau du site de geneve.site et toutes les suspicion d'une autre utilisation illicite ou
						abusive
						du site Internet que l'adresse IP est analysée aux fins de clarification et de défense et, le
						cas
						échéant, utilisée dans le cadre d'une procédure pénale pour l'identification et les procédures
						civile et pénale à l'encontre de l'utilisateur concerné.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Veuillez vous référer à la section
						Tracking-Tools ci-dessous pour de plus amples informations en relation avec les fichiers de
						protocole.</p>

					<span style={{
						fontSize: '1.3em',
						fontWeight: 'bold',
						color: '#3e8987',
						display: 'block',
						marginTop: '30px',
						marginBottom: '14px'
					}}>EN CAS D'INSCRIPTION AUX NEWSLETTERS, D'UTILISATION DES FORMULAIRES DE CONTACT OU DU SERVICE DE MESSAGERIE SUR GENEVE.SITE</span>
					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Pour recevoir la newsletter ou prendre
						contact
						avec nous au moyen d'un formulaire, la saisie véridique de certaines ou toutes les données
						personnelles suivantes est nécessaire:</p>

					<ul style={{ padding: '20px' }}>
						<li>adresse e-mail</li>
						<li>prénom</li>
						<li>nom</li>
						<li>téléphone</li>
					</ul>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Ces indications nous sont nécessaires pour
						traiter votre requête, vous envoyer des newsletters, et/ou utiliser les données à des fins de
						marketing ou commerciale.</p>

					<span style={{
						fontSize: '1.3em',
						fontWeight: 'bold',
						color: '#3e8987',
						display: 'block',
						marginTop: '30px',
						marginBottom: '14px'
					}}>TRANSMISSION DES DONNÉES À DES TIERS</span>
					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Nous ne transmettons vos données que si
						vous y
						avez expressément consenti, si la loi nous y oblige ou dans la mesure où il est nécessaire pour
						faire valoir nos droits. Nous transmettons également vos données à des entreprises qui nous sont
						liées.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Nous transmettons par ailleurs les données
						personnelles d'utilisateurs à des tiers dans la mesure où cela est nécessaire dans le cadre de
						l'utilisation du site Internet ainsi que pour répondre à des questions, traiter des demandes de
						renseignements ou fournir le cas échéant des prestations de service exigées par l'utilisateur.
						L'utilisation par les tiers des données transmises à cet effet est strictement limitée.</p>

					<span style={{
						fontSize: '1.3em',
						fontWeight: 'bold',
						color: '#3e8987',
						display: 'block',
						marginTop: '30px',
						marginBottom: '14px'
					}}>DROIT AUX RENSEIGNEMENTS, EFFACEMENT ET CORRECTION</span>
					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Vous pouvez à tout moment demander des
						renseignements sur vos données personnelles que nous avons enregistrées. Les demandes de
						renseignements doivent être soumises par écrit avec preuve d'identité. De même, vous avez le
						droit
						d'exiger à tout moment l'effacement ou la rectification de vos données enregistrées chez
						nous.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Vous pouvez faire par e-mail une demande
						correspondante à info<span></span>@bijouterie-lunas.ch. Vous trouverez d'autres possibilités de
						contact sous « Contact ».</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Veuillez noter que certaines données
						doivent,
						selon la loi, être conservées pendant une durée déterminée. De telles données doivent donc
						rester
						enregistrées chez nous jusqu'à expiration de ces délais. Nous bloquons ces données dans notre
						système et ne les utilisons que pour satisfaire les directives légales.</p>

					<span style={{
						fontSize: '1.3em',
						fontWeight: 'bold',
						color: '#3e8987',
						display: 'block',
						marginTop: '30px',
						marginBottom: '14px'
					}}>SÉCURITÉ DES DONNÉES</span>
					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Nous nous servons de mesures de sécurité
						techniques et organisationnelles appropriées nous semblant adéquates pour protéger vos données
						que
						nous avons enregistrées contre la manipulation, la perte partielle ou totale et l'accès non
						autorisé
						de tiers. Nos mesures de sécurité sont adaptées en permanence conformément à l'évolution
						technologique.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Nous prenons également très au sérieux
						notre
						propre protection des données internes à l'entreprise. Nos collaborateurs et les entreprises
						prestataires de services que nous avons mandatées sont tenus à la confidentialité et au respect
						des
						dispositions légales de protection des données. L'accès aux données personnelles n'est par
						ailleurs
						accordé que dans la mesure du nécessaire.</p>

					<span style={{
						fontSize: '1.3em',
						fontWeight: 'bold',
						color: '#3e8987',
						display: 'block',
						marginTop: '30px',
						marginBottom: '14px'
					}}>COOKIES</span>
					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Les cookies aident, entre autres, à rendre
						votre visite sur le site de geneve.site, plus simple, plus agréable et plus utile. Les cookies
						sont des
						fichiers d'information que votre navigateur Internet enregistre automatiquement sur le disque
						dur de
						votre ordinateur lorsque vous visitez notre site Internet.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Les cookies n'endommagent pas le disque dur
						de
						votre ordinateur et ne nous transmettent pas vos données personnelles.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Une demande d'acceptation des cookies se
						fera
						sur les pages de geneve.site. Vous pouvez toutefois configurer votre navigateur de façon à ce
						qu'aucun
						cookie ne soit classé sur votre ordinateur. Vous trouverez de plus amples informations à ce
						sujet
						dans les options de paramétrage de votre navigateur.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>La désactivation des cookies peut toutefois
						avoir pour conséquence que vous ne puissiez utiliser toutes les fonctions de notre site.</p>

					<span style={{
						fontSize: '1.3em',
						fontWeight: 'bold',
						color: '#3e8987',
						display: 'block',
						marginTop: '30px',
						marginBottom: '14px'
					}}>TRACKING-TOOLS</span>
					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Le site Internet de geneve.site utilisent
						Google
						Analytics, un service d'analyse de sites Internet fourni par Google Inc.
						(https://www.google.com).
						Les données collectées par le biais de cookies déposés par ce site sont collectées par Google
						Analytics, pour une durée de 14 mois. Google Analytics utilise des méthodes permettant une
						analyse
						de l'utilisation du site Internet , comme par exemple les «cookies» (voir ci-dessus «Cookies»).
						Les
						informations sur l'utilisation du site Internet sont en règle générale transmises à un serveur
						Google aux USA où elles sont enregistrées. Du fait de l'activation de l'anonymisation des IP sur
						ce
						site Internet, l'adresse IP est raccourcie dans les États membres de l'Union européenne ou dans
						d'autres États signataires de la Convention sur l'Espace économique européen ainsi qu'en Suisse
						avant la transmission aux USA. L'adresse IP complète est transmise à un serveur de Google aux
						USA,
						où elle est raccourcie dans de seuls cas exceptionnels. L'adresse IP anonymisée transmise dans
						le
						cadre de Google Analytics par le navigateur de l'utilisateur n'est pas regroupée avec d'autres
						données de Google.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Pour votre information, dans le cadre des
						données collectées selon ce qui précède, nous enregistrons les données suivantes:</p>

					<ul style={{ padding: '20px' }}>
						<li>l'adresse IP de l'ordinateur demandeur,</li>
						<li>la date et l'heure de l'accès,</li>
						<li>le nom et l'URL des données consultées,</li>
						<li>le site Internet à partir duquel il a été accédé à notre domaine,</li>
						<li>le système d'exploitation de votre ordinateur et du navigateur que vous utilisez,</li>
						<li>le pays d'où l'accès à notre site Internet a lieu,</li>
						<li>le nom de votre fournisseur d'accès à l'Internet.</li>
					</ul>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Les informations sont utilisées pour
						analyser
						l'utilisation du site Internet, pour composer des rapports sur les activités sur les sites
						Internet
						et pour fournir d'autres prestations de service liées à l'utilisation des sites Internet aux
						fins
						d'étude de marché et d'organisation des sites Internet conformément aux besoins.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Les utilisateurs peuvent empêcher la saisie
						des
						données produites par le cookie et se rapportant à l'utilisation du site Internet par
						l'utilisateur
						concerné (y. c. l'adresse IP) à Google ainsi que le traitement de ces données par Google, en
						téléchargeant et en installant le browser-plugin disponible sous le lien suivant:</p>

					<a href="https://tools.google.com/dlpage/gaoptout?hl=fr" target="_blank"
					   rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=fr</a>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Les utilisateurs peuvent, en alternative au
						browser-plugin, cliquer sur la bannière située en bas de page pour empêcher la saisie par Google
						Analytics sur le site Internet à l'avenir. Un opt-out-cookie est classé sur le terminal de
						l'utilisateur. Si les utilisateurs effacent des cookies (voir ci-dessus «Cookie»), il faudra à
						nouveau cliquer sur cette bannière située en bas de page.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>
						Le site utilise plusieurs services de HubSpot Inc, notamment pour le formulaire de contact, le
						système
						de messagerie instantané ainsi qu’à des fins analytiques et de gestion marketing. Les données
						générées
						par les cookies concernant votre utilisation du site (y compris votre adresse IP) seront
						transmises et
						stockées par HubSpot Inc sur des serveurs pouvant être situés à l’étranger, y compris dans un
						pays offrant
						un niveau inférieur de protection des données.
					</p>

					<a href="https://legal.hubspot.com/fr/privacy-policy" target="_blank"
					   rel="noopener noreferrer">https://legal.hubspot.com/fr/privacy-policy</a>

					<span style={{
						fontSize: '1.3em',
						fontWeight: 'bold',
						color: '#3e8987',
						display: 'block',
						marginTop: '30px',
						marginBottom: '14px'
					}}>FONCTIONNALITÉS DES MÉDIAS SOCIAUX</span>
					<span>LIENS VERS NOS PRÉSENCES DANS LES MÉDIAS SOCIAUX</span>
					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Nous avons intégré sur le site de
						geneve.site des
						liens sur nos profils de médias sociaux sur les réseaux sociaux suivants:</p>

					<ul style={{ padding: '20px' }}>
						<li>Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA,</li>
						<li>Instagram Inc., 1601 Willow Road, Menlo Park, California 94025, USA</li>
						<li>YouTube, un service exploité par Google Inc.</li>
					</ul>
					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Certains contenus (textes et photos, pseudo
						de
						l'utilisateur) qui sont publiés sur les réseaux sociaux en lien direct avec geneve.site peuvent
						être
						repris pour les afficher sur les sites cités.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Vous êtes relié au réseau social
						sélectionné en
						cliquant sur les icônes concernés des réseaux sociaux. Vous devez pour cela toutefois vous
						connecter
						à votre compte d'utilisateur correspondant, ou déjà y être connecté.</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Un lien direct entre votre navigateur et le
						serveur du réseau social concerné est établi si vous choisissez une des fonctionnalités mises à
						disposition et cliquez sur le symbole du réseau social concerné. Le réseau reçoit ainsi
						l'information que vous avez visité notre site Internet avec votre adresse IP et consulté le
						lien.
						Lorsque vous consultez un lien vers un réseau pendant que vous êtes connecté à votre compte dans
						le
						réseau concerné, les contenus de votre site peuvent être reliés à votre profil dans le réseau,
						ce
						qui signifie que votre réseau peut attribuer votre visite sur notre site Internet directement à
						votre compte utilisateur. Vous devriez, si vous souhaitez empêcher cela, vous déconnecter avant
						d'actionner des liens correspondants. Une attribution a lieu dans tous les cas si vous vous
						connectez au réseau concerné après avoir actionné le lien.</p>

					<span style={{
						fontSize: '1.3em',
						fontWeight: 'bold',
						color: '#3e8987',
						display: 'block',
						marginTop: '30px',
						marginBottom: '14px'
					}}>CONTACT</span>
					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Si vous avez des questions concernant la
						protection des données sur notre site Internet, si vous souhaitez un renseignement ou obtenir
						l'effacement de vos données, veuillez prendre contact en envoyant un e-mail à info<span></span>@bijouterie-lunas.ch.
					</p>

					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Par lettre, transmettez votre requête à
						l'adresse suivante:</p>

					<ul style={{ padding: '20px' }}>
						<li>nammu.ch SNC</li>
						<li>Route du Mandement, 15</li>
						<li>1217 Meyrin</li>
						<li>Genève, Suisse</li>
					</ul>

					<span style={{
						fontSize: '1.3em',
						fontWeight: 'bold',
						color: '#3e8987',
						display: 'block',
						marginTop: '30px',
						marginBottom: '14px'
					}}>CONSENTEMENTS ET AUTORISATIONS</span>
					<p style={{ margin: 0, padding: 0, paddingTop: '10px' }}>Par l'acceptation de notre politique de
						protection de données, vous signifiez votre accord et nous autorisez conformément à la teneur
						applicable du texte ci-dessus. A des fins de clarification, votre accord et les autorisations
						portent en particulier sur l'un et/ou l'autre des éléments suivants (selon le contexte dans
						lequel
						les données sont traitées), étant précisé que vous pouvez les révoquer, en tout ou partie, à
						tout
						moment et pour l'avenir. Ainsi, votre consentement peut impliquer, selon le(s) cas concerné(s)
						que
						vous acceptez que nammu.ch, le cas échéant avec l'assistance de tiers :</p>

					<ul style={{ padding: '20px' }}>
						<li>traite vos données personnelles afin de fournir
							les prestations ou les services que vous sollicitez de nammu et/ou l'une ou l'autre des
							entités qui
							lui sont liées ;
						</li>
						<li>vous envoie régulièrement par e-mail des
							informations sur nammu et/ou des invitations à des événements en rapport avec geneve.site ;
						</li>
						<li>collecte des cookies aux fins de recueillir,
							enregistrer et utiliser des données d'utilisation à des fins de marketing ;
						</li>
						<li>vous propose, le cas échéant par publicité
							personnalisée, des manifestations en rapport avec geneve.site, incluant notamment des
							invitations,
							enquêtes de satisfaction, offres et services particuliers, au besoin sur la base de données
							complémentaires à soumettre à une analyse spécifique.
						</li>
					</ul>
				</Modal>
			</section>
		</>
	);
};

export default Contact;

